import { TBankRequisitesResponse, TTransferBankDetails, TTransferDetails } from "shared/definitions";
import { getAddressString } from "shared/utils/userDetails/address";

export function getBankTransferDetails(
  requisitesResponse: TBankRequisitesResponse,
  details: TTransferDetails,
): TTransferBankDetails & TTransferDetails {
  return {
    bankName: requisitesResponse.bankName,
    bankAddress: getAddressString(requisitesResponse.bankAddress, true),
    bic: requisitesResponse.bicOrSwift,
    destination: requisitesResponse.recipient,
    destinationAddress: getAddressString(requisitesResponse.recipientAddresses),
    unp: requisitesResponse.payerTaxId,
    accountNumber: requisitesResponse.accountNumber,
    ...details,
  };
}
