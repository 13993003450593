import { Button, Center, Loader, Modal, SegmentedControl } from "@mantine/core";
import { Card } from "components/common/Card";
import { observer } from "mobx-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { TDepositRequisites, TFlowType, TTransferDetails } from "shared/definitions";
import { getTransferAccountDetails } from "shared/utils/api/transferAccountDetails";
import { formatFiat } from "shared/utils/formatCurrency";
import { printDetails } from "shared/utils/print/printTransferDetails";
import { useStores } from "store/root/provider";
import { CopyContent } from "components/common/CopyContent";
import styles from "./AccountDetails.module.scss";
import { getBankTransferDetails } from "./getBankTransferDetails";

type TProps = {
  onClose: () => void;
  amount: number;
  currency: string;
  fiatFlow: TFlowType;
};

export const AccountDetails = observer((props: TProps) => {
  const { user, currencies } = useStores();
  const [detailsType, setDetailsType] = useState<"qr" | "details">("details");
  const [loading, setLoading] = useState(true);
  const [depositRequisites, setDepositRequisites] = useState<TDepositRequisites | null>(null);
  const [noDetailsError, setNoDetailsError] = useState(false);
  const ref = useRef<HTMLTableElement>(null);

  const transferDetails: TTransferDetails = useMemo(
    () => ({
      purpose: `Пополнение аккаунта ${user.userDetails?.fullName} ID ${user.userDetails?.publicId}`,
      sum: formatFiat(props.amount),
      currency: props.currency,
    }),
    [props.amount, props.currency, user.userDetails?.fullName, user.userDetails?.publicId],
  );
  const currencyInfo = useMemo(() => {
    return currencies.getFiatCurrencyByTicker(props.currency);
  }, [currencies, props.currency]);

  useEffect(() => {
    if (currencyInfo) {
      getTransferAccountDetails(currencyInfo.id).then((res) => {
        if (res?.data) {
          // tmp enabled
          setDepositRequisites(getBankTransferDetails(res.data, transferDetails));
        } else {
          setNoDetailsError(true);
        }
        setLoading(false);
      });
    }
  }, [currencyInfo, transferDetails]);
  return (
    <Modal classNames={{ modal: styles.modal }} title="Реквизиты для пополнения счета" opened onClose={props.onClose}>
      <div className={styles.container}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <SegmentedControl
              value={detailsType}
              color="violet"
              onChange={(value: "qr" | "details") => setDetailsType(value)}
              data={[
                {
                  value: "details",
                  label: <Center>Реквизиты</Center>,
                },
                {
                  value: "qr",
                  label: <Center>QR-код</Center>,
                },
              ]}
            />

            {noDetailsError && (
              <Card mode="danger">
                По техническим причинам невозможно сгенерировать счет, попробуйте позднее или обратитесь в техническую
                поддержку
              </Card>
            )}
            {depositRequisites && transferDetails && (
              <>
                {props.fiatFlow === TFlowType.cash_register && (
                  <Card mode="danger">Покажите данный счет или QR-код кассиру при внесении средств</Card>
                )}
                <Card className={styles.details}>
                  <table ref={ref}>
                    <tbody>
                      <tr>
                        <td className={styles.fieldName}>Название банка</td>
                        <td>
                          <CopyContent content={depositRequisites.bankName} />
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.fieldName}>Адрес банка</td>
                        <td>
                          <CopyContent content={depositRequisites.bankAddress} />
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.fieldName}>ВIС SWIFT</td>
                        <td>
                          <CopyContent content={depositRequisites.bic} />
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.fieldName}>Получатель</td>
                        <td>
                          <CopyContent content={depositRequisites.destination} />
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.fieldName}>Адрес получателя</td>
                        <td>
                          <CopyContent content={depositRequisites.destinationAddress} />
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.fieldName}>УНП</td>
                        <td>
                          <CopyContent content={depositRequisites.unp} />
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.fieldName}>Номер счета</td>
                        <td>
                          <CopyContent content={depositRequisites.accountNumber} />
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.fieldName}>Валюта счета</td>
                        <td>
                          <CopyContent content={transferDetails.currency} />
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.fieldName}>Сумма</td>
                        <td>
                          <CopyContent content={transferDetails.sum} />
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.fieldName}>Назначение платежа</td>
                        <td>
                          <CopyContent content={transferDetails.purpose} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              </>
            )}
          </>
        )}
        <div className={styles.footer}>
          <Button className={styles.closeButton} onClick={props.onClose} variant="default" color="green">
            Закрыть
          </Button>
          <Button
            onClick={() => {
              if (depositRequisites) {
                printDetails(depositRequisites);
              }
            }}>
            Скачать счет
          </Button>
        </div>
      </div>
    </Modal>
  );
});
